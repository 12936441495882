import { DefaultTheme } from 'styled-components'
import { ConsultTypeEnum, PriorityLevelEnum } from '../../types/consultType'

export const getColorByConsultType = ({
  type,
  priority,
  props,
}: {
  type: string
  priority?: number
  props: { theme: DefaultTheme }
}) => {
  if (priority && priority >= PriorityLevelEnum.Urgent) {
    return props.theme.colors.darkRed
  }
  switch (type) {
    case ConsultTypeEnum.FollowUp:
      return `${props.theme.colors.orange}`
    case ConsultTypeEnum.Refill:
      return `${props.theme.colors.coral}`
    case ConsultTypeEnum.Initial:
      return `${props.theme.colors.midGreen}`
    case ConsultTypeEnum.Message:
      return `${props.theme.colors.blue}`
    case ConsultTypeEnum.Reactivation:
      return `${props.theme.colors.darkGreen}`
    default:
      return `${props.theme.colors.midGrey1}`
  }
}
