import React from 'react'
import styled from 'styled-components'

interface AlignmentProps extends React.HTMLAttributes<HTMLDivElement> {
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline'
  children: any
  flexDirection?: 'column' | 'row'
  flexGrow?: string | number
  flexWrap?: 'wrap' | 'reverse' | 'nowrap'
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between'
  padding?: string
  width?: string
  height?: string
}

export const StyledAlignment = styled.div<AlignmentProps>`
  display: flex;
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection};
  flex-grow: ${props => props.flexGrow};
  flex-wrap: ${props => props.flexWrap};
  justify-content: ${props => props.justifyContent};
  padding: ${props => props.padding};
  width: ${props => props.width};
  height: ${props => props.height};
`

const Alignment = (props: AlignmentProps) => (
  <StyledAlignment {...props}>{props.children}</StyledAlignment>
)

export default Alignment
