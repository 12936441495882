import React from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import styled, { css } from 'styled-components'

interface InputProps {
  type: 'text' | 'number' | 'email' | 'password'
  align: 'left' | 'right' | 'center'
  register: UseFormRegisterReturn
  placeholder?: string
}

export const InputStyles = css`
  width: 100%;
  display: block;
  border: none;
  outline: none;
  background: transparent;
  font-family: var(--maisonNeue-medium);
  font-size: 16px;
  line-height: 50px;
  border-bottom: 2px solid;
`

const InputWrapper = styled.input`
  ${InputStyles}
`

const Input = (componentProps: InputProps) => {
  const { align, register, ...props } = componentProps
  return <InputWrapper style={{ textAlign: align }} {...register} {...props} />
}

export default Input
