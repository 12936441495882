import styled from 'styled-components'
import React from 'react'

interface DividerProps {
  margin: number
}

const StyledDivider = styled.div<DividerProps>`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: ${props => (props.margin ? props.margin : 0)}px 0;
`
const Divider = (props: DividerProps) => <StyledDivider {...props} />

export default Divider
