import { CustomerType } from './customerType'
import { MedicalSurvey, MedicalSurveyResponse, OtherSurveysType } from './surveyTypes'

// Events set to backend to change the status
export enum ConsultEventEnum {
  Cancel = 'cancel',
  Complete = 'complete',
  Disqualify = 'disqualify',
  Start = 'start',
}

export enum ConsultStatusEnum {
  New = 'new',
  Assigned = 'assigned',
  Started = 'started',
  Deferred = 'deferred',
  Completed = 'completed',
  Rejected = 'rejected',
  Canceled = 'canceled',
}

export enum ConsultScopesEnum {
  New = 'new',
  Assigned = 'assigned',
  Started = 'started',
  NotStarted = 'notStarted',
  Deferred = 'deferred',
  Completed = 'completed',
  Rejected = 'rejected',
  Canceled = 'canceled',
  Open = 'open',
  Prioritized = 'prioritized',
  Urgent = 'urgent',
  UrgentUnassigned = 'urgentUnassigned',
  Synchronous = 'synchronous',
  Imminent = 'imminent',
  SynchronousNeedsAttention = 'synchronousNeedsAttention',
}

export enum ConsultActionRequiredEnum {
  Reply = 'reply',
  LabsReview = 'labs-review',
}

export enum ConsultAssignmentTagsEnum {
  Continuity = 'continuity',
  Pippen = 'pippen',
}

export enum PriorityLevelEnum {
  Default = 0,
  Important = 500,
  Urgent = 1000,
}

export const EscalationsScope = 'escalations'

export enum ConsultFilterTabType {
  PatientEscalations = 'Patient Escalations',
  AllConsultations = 'All Async',
  Urgent = 'Urgent',
  Important = 'Important',
  Live = 'Scheduled Care Calls',
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Deferred = 'Deferred',
  Completed = 'Completed',
}

export const ActiveStatuses = [
  ConsultStatusEnum.New,
  ConsultStatusEnum.Assigned,
  ConsultStatusEnum.Started,
  ConsultStatusEnum.Deferred,
]

export const FinishedStatuses = [
  ConsultStatusEnum.Completed,
  ConsultStatusEnum.Canceled,
  ConsultStatusEnum.Rejected,
]

export enum ConsultCompletionTypeEnum {
  PrescribedMedication = 'prescribed-medication',
  NoMedication = 'no-medication',
  NoResponse = 'no-response',
  Resolved = 'resolved',
  ProviderEscalation = 'provider-escalation',
  MemberNoShow = 'member-no-show',
  ProviderNoShow = 'provider-no-show',
}

export enum ConsultTypeEnum {
  FollowUp = 'follow-up',
  Initial = 'initial',
  Message = 'message',
  Reactivation = 'reactivation',
  Refill = 'refill',
}

export enum MessageReasonEnum {
  Support = 'support',
  Refill = 'refill',
  GeneralMedication = 'general-medication',
  SideEffect = 'side-effect',
  ChangeMedication = 'change-medication',
  OtherMedicalQuestion = 'other-medical-question',
  Cancel = 'cancel',
}

export type ConsultType = {
  id: string
  staffId: string
  customerId: string
  medicalSurveyId: string | null
  status: ConsultStatusEnum
  type: ConsultTypeEnum
  dosespotId?: string
  disqualificationReason?: string | null
  createdAt: Date | string | null
  updatedAt: Date | string | null
  customer: CustomerType
  reason?: MessageReasonEnum | null
  supportChatId: string | null
  completionType?: string
  isImportant?: boolean
  priority?: number
  isSynchronous: boolean
  scheduledAt?: Date | string | null
}

export type ConsultNoteType = {
  id: string
  consultId: string
  staffId: string
  content: string
  createdAt: string
  updatedAt: string
  consult: ConsultType
  authorOverride?: string
  author: {
    firstName: string
    lastName: string
    preferredTitle?: string
  }
}
export interface ConsultFill {
  createdAt: string
  customerId: string
  daysSupply: number
  deliveredAt: Date
  eta: Date
  fillPriorAuthStatusLogs: FillPriorAuthStatusLogs[]
  followUpSentAt: Date | null
  id: string
  priorAuthStatus: string
  status: string
  trackingUrl: string
  updatedAt: Date
}
export interface FillPriorAuthStatusLogs {
  newPriorAuthStatus: string
  notes: PAStatusNotes[]
  previousPriorAuthStatus: string
  timestamp: string
}
export interface PAStatusNotes {
  content: string
  author: {
    id: string
    name: string
  }
}
export type CustomerCoachingBlocksType = {
  id: string
  customerId: string
  currentBlock: string
  scheduledBlocks: string[]
  previousBlocks: string[]
  abandonedBlocks: string[]
  createdAt: string
  updatedAt: string
}

export type ConsultResponseType = {
  consult: ConsultType
  rankedMedications: RankedMedicationType[]
  latestSurvey: MedicalSurvey & {
    responses: { [key: string]: MedicalSurveyResponse }
  }
  otherSurveys?: Array<OtherSurveysType>
  coachingBlocks: CustomerCoachingBlocksType | null
  customer: CustomerType
  joinUrl: string
}

export type RankedMedicationType = {
  id: string
  name: string
  score: number
}

export type ReasonType = {
  id: string
  modifier: string
  explanation: string
}
export type ConsultRankedMedicationType = {
  id: string
  medicationId: string
  name: string
  score: number
  reasons?: ReasonType[]
}
