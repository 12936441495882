import type {
  ConsultAssignmentTagsEnum,
  ConsultCompletionTypeEnum,
  ConsultType,
  ConsultNoteType,
  ConsultResponseType,
  ConsultRankedMedicationType,
} from 'types/consultType'
import type { SortOrderDirection } from 'types/genericTypes'
import { MessageSender } from 'types/messageType'
import type { PhotonPrescription } from 'types/prescriptionType'

import { request } from './api'

const checkConsultPrescriptions = async (consultId: string) => {
  return request<{ hasPrescriptions: boolean }>({
    uri: `/consults/${consultId}/check-prescriptions`,
    options: { method: 'GET' },
  })
}

const retrievePendingPrescriptions = async (consultId: string) => {
  return request<{ prescriptions: PhotonPrescription[] }>({
    uri: `/consults/${consultId}/pending-prescriptions`,
    options: { method: 'GET' },
  })
}

const createNote = ({ consultId, content }: { consultId: string; content: string }) => {
  const requestData = {
    sendAt: new Date().toISOString(),
    content,
    sender: MessageSender.Staff,
  }
  return request<ConsultNoteType>({
    uri: `/consults/${consultId}/note`,
    options: {
      method: 'POST',
      data: requestData,
    },
  })
}

const retrieveConsults = async ({
  page = 0,
  pageSize = 50,
  sortBy,
  sortDirection,
  scope,
}: {
  page: number
  pageSize?: number
  sortBy: string
  sortDirection: SortOrderDirection
  scope?: string
}) => {
  return request<{ totalCount: number; consults: Array<ConsultType> }>({
    uri: '/consults',
    options: {
      method: 'GET',
      params: {
        page,
        pageSize,
        orderBy: `${sortBy},${sortDirection}`,
        scope,
      },
    },
  })
}

const retrieveUnassignedConsults = ({
  page = 0,
  pageSize = 50,
  sortBy,
  sortDirection,
  minPriority,
}: {
  page?: number
  pageSize?: number
  sortBy: string
  sortDirection: SortOrderDirection
  minPriority: number
}) => {
  return request<{ totalCount: number; consults: ConsultType[] }>({
    uri: '/consults/unassigned',
    options: {
      method: 'GET',
      params: {
        page,
        pageSize,
        orderBy: `${sortBy},${sortDirection}`,
        status,
        minPriority,
      },
    },
  })
}

const retrieveConsultById = (consultId: string) => {
  return request<ConsultResponseType>({
    uri: `/consults/${consultId}`,
    options: { method: 'GET' },
  })
}

const retrieveConsultCounts = async () => {
  return request<Record<string, number>>({
    uri: '/consults/counts',
    options: { method: 'GET' },
  })
}

const searchConsults = async ({
  customerName,
  consultId,
}: {
  customerName?: string
  consultId?: string
}) => {
  return request<{ nextPage: number | null; consults: Array<ConsultType> }>({
    uri: `/consults/search`,
    options: {
      method: 'GET',
      params: { customerName, consultId },
    },
  })
}

const sendSupportTriageForConsult = ({
  consultId,
  reason,
  message,
}: {
  consultId: string
  reason: string[]
  message: string
}) => {
  return request({
    uri: `/consults/${consultId}/support-triage`,
    options: {
      method: 'POST',
      data: {
        reason,
        message,
      },
    },
  })
}

const assignConsult = ({ consultId }: { consultId: string }) => {
  return request<ConsultType>({
    uri: `/consults/${consultId}/assign`,
    options: { method: 'PATCH' },
  })
}

const unassignConsult = ({ consultId }: { consultId: string }) => {
  return request<ConsultType>({
    uri: `/consults/${consultId}/unassign`,
    options: { method: 'PATCH' },
  })
}

const enqueueConsult = (
  consultId: string,
  params?: {
    assignmentTags?: ConsultAssignmentTagsEnum[]
    priority?: number
  },
): Promise<ConsultType> => {
  return request<ConsultType>({
    uri: `/consults/queue`,
    options: {
      method: 'PUT',
      data: { consultId, ...params },
    },
  })
}

const setConsultStatus = ({
  status,
  consultId,
  data,
}: {
  status: string
  consultId: string
  data?: {
    disqualificationReason?: string
    completionType?: ConsultCompletionTypeEnum
    supportTriage?: {
      reason: string[]
      message: string
    }
    escalationData?: {
      priority: number
      situation: string
      background: string
      assessment: string
      recommendation: string
    }
    forceLocalFill?: boolean
  }
}) => {
  return request<ConsultType>({
    uri: `/consults/${consultId}/${status}`,
    options: {
      method: 'PATCH',
      data,
    },
  })
}

const setConsultSupportChatId = ({
  consultId,
  supportChatId,
}: {
  consultId: string
  supportChatId: string
}) => {
  return request<ConsultType>({
    uri: `/consults/${consultId}/support-chat`,
    options: {
      method: 'PATCH',
      data: { supportChatId },
    },
  })
}

const retrieveConsultRankedMedications = (
  consultId: string,
): Promise<ConsultRankedMedicationType[]> => {
  return request<ConsultRankedMedicationType[]>({
    uri: `/consults/${consultId}/ranked-medications`,
    options: { method: 'GET' },
  })
}

export {
  assignConsult,
  checkConsultPrescriptions,
  createNote,
  enqueueConsult,
  retrieveConsults,
  retrieveConsultById,
  retrieveConsultCounts,
  retrieveConsultRankedMedications,
  retrievePendingPrescriptions,
  retrieveUnassignedConsults,
  searchConsults,
  sendSupportTriageForConsult,
  setConsultStatus,
  setConsultSupportChatId,
  unassignConsult,
}
