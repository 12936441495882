import React from 'react'
import styled from 'styled-components'
import { ChevronRight } from '@styled-icons/boxicons-solid/ChevronRight'

interface ChevronProps {
  height?: number
  width?: number
  rotate?: number
}

const StyledImage = styled(ChevronRight)<ChevronProps>`
  transform: ${props => (props.rotate ? `rotate(${props.rotate}deg)` : '0deg')};
`

const Chevron = (props: ChevronProps) => {
  return <StyledImage {...props} />
}

export default Chevron
