import format from 'date-fns/format'

export enum DateFormatEnum {
  FullMonthDayFullYear = 'MMM, dd, yyyy',
  MonthDayFullYear = 'MM/dd/yyyy',
  MonthDayFullYear2 = 'yyyy-MM-dd',
  MonthDayYear = 'MM/dd/yy',
  MonthAbbrDayYear = 'LLL dd, yyyy',
  MonthDayFullYearHourMin = 'yyyy-MM-dd • kk:mm aa',
}

export enum TimeFormatEnum {
  HourMinutePeriod = 'hh:mm a',
}

export function formatDate(date: string, dateFormat: string) {
  return format(new Date(date), dateFormat)
}

export const dateIsValid = (dateStr: string) => {
  // YYYY-MM-DD
  const regex = /^\d{4}-\d{2}-\d{2}$/

  if (dateStr.match(regex) === null) {
    return false
  }

  const [year, month, day] = dateStr.split('-')

  const isoFormattedStr = `${year}-${month}-${day}`

  const date = new Date(isoFormattedStr)

  const timestamp = date.getTime()

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false
  }

  return date.toISOString().startsWith(isoFormattedStr)
}

export function sortByTime<T>({
  data,
  attrNames,
  option,
}: {
  data: T[]
  attrNames: string[]
  option?: string
}): T[] {
  data.sort((a, b) => {
    const attrNameA = attrNames.find((attrName: string) => {
      return !!(a as any)[attrName]
    })
    const dateAString = attrNameA ? (a as any)[attrNameA] : ''
    const attrNameB = attrNames.find((attrName: string) => {
      return !!(b as any)[attrName]
    })
    const dateBString = attrNameB ? (b as any)[attrNameB] : ''
    const dateA = new Date(dateAString)
    const dateB = new Date(dateBString)
    if (option === 'asc') {
      return dateA > dateB ? 1 : -1
    } else {
      return dateA < dateB ? 1 : -1
    }
  })
  return data
}
