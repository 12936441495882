export enum MessageSender {
  Staff = 'staff',
  Customer = 'customer',
  System = 'system',
}

export type MessageType = {
  id: string
  staffId: string
  customerId: string
  consultId: string
  sentAt: string
  content: string
  sender: MessageSender
  staff: {
    firstName: string
    lastName: string
  }
  customer: {
    firstName: string
    lastName: string
  }
  acknowledged?: boolean
}

export type ProviderQuestionType = {
  id: string
  customerId: string
  messageTypeDetails: string
  messageContents: string
  answeredAt: Date
}
export type SmartSnippet = {
  text: string
}
