import React from 'react'
import styled from 'styled-components'

interface FieldErrorProps {
  visible: boolean
  children: React.ReactNode | React.ReactNode[]
}

const FieldErrorStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  color: red;
  padding-top: 1rem;
  line-height: 1rem;
`

const FieldError = (props: FieldErrorProps) => {
  const { visible, children } = props
  return visible ? <FieldErrorStyles>{children}</FieldErrorStyles> : null
}

export default FieldError
